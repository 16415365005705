<template>
    <div>
        <pop ref="pop" title="请选择赠送的权益" @toAudit="gifts">
            <el-radio-group v-model="value">
                <el-radio border v-for="item in list" :key="item.id" :label="item.id">
                    <span>{{ item.name }}</span>
                </el-radio>
            </el-radio-group>
        </pop>
    </div>
</template>

<script>
export default {
    data() {
        return {
            detail: "",
            list: [],
            value: "",
        };
    },
    methods: {
        async getRights() {
            let { data } = await this.$http.get("/admin/Finance/getRights");
            this.list = data.data;
        },
        async gifts() {
            let { data: res } = await this.$http.post("/admin/Finance/sendRights", {
                userId: this.detail.userId,
                rightsId: this.value,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$refs.pop.show = false;
                this.$emit('getlist')
            }
        },
        init(val) {
            this.detail = val;
            this.$refs.pop.show = true;
        },
    },
    mounted() {
        this.getRights();
    },
};
</script>

<style lang="less" scoped></style>
